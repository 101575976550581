import React from "react"
import Layout from "../hoc/Layout"
import classes from "../styles/notes.module.styl"

import { graphql, Link } from "gatsby"
import { isMobile } from "react-device-detect";

const Notes = (props) => {
  let notesArr = []
  props.data.allMdx.edges.forEach(edge => {
    if (!isMobile) {
      notesArr.push(
        <tr key={ edge.node.fields.slug }>
          <td><Link to={ `/${edge.node.fields.slug}` }>{ edge.node.fields.pageType }</Link></td>
          <td><Link to={ `/${edge.node.fields.slug}` }>{ edge.node.fields.pageTitle }</Link></td>
          <td><Link to={ `/${edge.node.fields.slug}` }>{ edge.node.frontmatter.date }</Link></td>
        </tr>
      )
    } else {
      notesArr.push(
        <tr key={ edge.node.fields.slug }>
          <td>
            <Link to={ `/${edge.node.fields.slug}` }>
              <span className={ classes.pageTitle }>{ edge.node.fields.pageTitle }</span>
              <span className={ classes.pageMetadata }>{ edge.node.fields.pageType } / { edge.node.frontmatter.date }</span>

            </Link>
          </td>
        </tr>
      )
    }
  })

  let tableHead;
  if (!isMobile) {
    tableHead = (
      <thead>
        <tr>
          <th>Type</th>
          <th>Title</th>
          <th>Last Updated</th>
        </tr>
      </thead>
    )
  } else {
    tableHead = (
      <thead>
        <tr>
          <th>Catalogue</th>
        </tr>
      </thead>
    )
  }

  return (
    <Layout location={ props.location } pageTitle="Notes">
      <div className={ classes.headContainer }>
        <h1>Notes</h1>
        <p>The notes catalogue. Will have some sort of filtering eventually.</p>
      </div>
      <div className={ classes.notesContainer }>
        <table>
          { tableHead }
          <tbody>
            { notesArr }
          </tbody>
        </table>
      </div>
    </Layout>
  )
}

export default Notes

export const pageQuery = graphql`
  query {
    allMdx(
      sort:{
        fields:[frontmatter___date]
        order: DESC
      }
    ) {
      edges {
          node {
            frontmatter {
              date(formatString: "YYYY-MM-DD")
            }
            fields {
              slug 
              pageType
              pageTitle
            }
          }
        }
      }
  }
`
